@if (loading) {
    <div class="text-center mt-5 fs-2"><em class="fas fa-spinner fa-spin"></em></div>
} @else {

    <div class="container-fluid">

        <div class="row justify-content-center">
            <div class="col-md-10 col-12">
                <div class="my-3">
                    <a class="btn btn-validate" routerLink="/admin/news">Retour</a>
                </div>
                @if (news && !createMode) {
                    <h2 class="text-center my-4">
                        Modification de l'actualité {{ news.title }}
                    </h2>
                } @else if (createMode) {
                    <h2 class="text-center my-4">
                        Création d'une actualité
                    </h2>
                }

                <div class="row my-2">
                    <label for="image" class="col-sm-4 col-form-label">Image </label>
                    <div class="col-sm-8">
                        <form (ngSubmit)="uploadImageForGiftNews()" class="row align-items-center g-3 my-2">
                            <div class="col-xs-4 col-sm-auto float-start">
                                <input (change)="changeImageFile($event.target)" class="form-control" id="image"
                                       type="file">
                            </div>
                            @if (imageFile) {
                                <div class="col-sm-8 float-start d-flex w-auto">
                                    <button class="btn btn-validate me-1 min-w-105" type="submit">
                                        @if (!isUploadingImage) {
                                            Télécharger
                                        } @else {
                                            <em class="fas fa-spinner fa-spin"></em>
                                        }
                                    </button>
                                    @if (imageUrl && !isUploadingImage) {
                                        <button class="btn btn-validate"
                                                type="button"
                                                [cdkCopyToClipboard]="'<img src=&quot;' + imageUrl + '&quot; class=&quot;d-block mx-auto my-3&quot;>'"
                                                (cdkCopyToClipboardCopied)="copyImageUrlToClipboardCopied()">
                                            Copier le chemin
                                        </button>
                                    }
                                </div>
                            }
                        </form>
                    </div>
                </div>

                @if (newsForm) {
                    <form [formGroup]="newsForm">
                        <div class="row my-2">
                            <div class="col-sm-4 col-form-label">Titre</div>
                            <mat-form-field appearance="fill" class="col-sm-8">
                                <input matInput formControlName="title" type="text">
                                <mat-error>Le titre est obligatoire</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="row my-2">
                            <div class="col-sm-4 col-form-label">Contenu</div>
                            <mat-form-field appearance="fill" class="col-sm-8">
                                <textarea matInput formControlName="content" rows="5"></textarea>
                                <mat-error>Le contenu est obligatoire</mat-error>
                            </mat-form-field>
                        </div>
                        @if (!createMode) {
                            <div class="row my-2">
                                <div class="col-sm-4 col-form-label">Statut</div>
                                <mat-form-field appearance="fill" class="col-sm-8">
                                    <mat-select formControlName="status" id="status">
                                        <mat-option value="draft">Brouillon</mat-option>
                                        <mat-option value="active">Publié</mat-option>
                                        <mat-option value="inactive">Masqué</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        }
                        <div class="row my-2">
                            <div class="col-sm-4 col-form-label">Visible dans le réseau par</div>
                            <div class="col-sm-8">
                                <div class="row">
                                    <mat-form-field appearance="fill" class="col-md-6">
                                        <mat-select formControlName="levelId" id="levelId">
                                            <mat-option [value]="-1">Tous</mat-option>
                                            @for (hierarchicalLevel of hierarchicalLevels; track hierarchicalLevel.id) {
                                                <mat-option  [value]="hierarchicalLevel.id">{{hierarchicalLevel.entity}}</mat-option>
                                            }
                                        </mat-select>
                                    </mat-form-field>
                                    @if (newsForm.controls.levelId.value !== -1) {
                                        <mat-form-field appearance="fill" class="col-md-6">
                                            <mat-label>Entité ciblées</mat-label>
                                            <mat-select formControlName="entityExternalId" id="entityExternalId" multiple>
                                                @if (branches.length) {
                                                    @for (branch of branches; track branch.externalId) {
                                                        <mat-option  [value]="branch.externalId">{{branch.externalId}} - {{branch.name}}</mat-option>
                                                    }
                                                } @else {
                                                    <mat-option  [value]="null">Aucune entité</mat-option>
                                                }
                                            </mat-select>
                                        </mat-form-field>
                                    }
                                </div>
                            </div>
                        </div>
                        <div class="row my-2">
                            <div class="col-sm-4 col-form-label">Date de publication</div>
                            <mat-form-field appearance="fill" class="col-sm-8">
                                <mat-label>Date de publication</mat-label>
                                <input matInput [matDatepicker]="publicationAt" formControlName="publicationAt">
                                <mat-datepicker-toggle matIconSuffix [for]="publicationAt" />
                                <mat-datepicker #publicationAt />
                            </mat-form-field>
                        </div>
                        <div class="row my-2">
                            <div class="col-sm-4 col-form-label">Date de dépublication</div>
                            <mat-form-field appearance="fill" class="col-sm-8">
                                <mat-label>Date de dépublication</mat-label>
                                <input matInput [matDatepicker]="unpublicationAt" formControlName="unpublicationAt">
                                <mat-datepicker-toggle matIconSuffix [for]="unpublicationAt" />
                                <mat-datepicker #unpublicationAt />
                            </mat-form-field>
                        </div>
                        <div class="row my-2">
                            <div class="col-sm-4 col-form-label">Publication permanente</div>
                            <mat-slide-toggle class="col-sm-8" formControlName="permanentPublication"></mat-slide-toggle>
                        </div>
                        <button (click)="save()" class="btn btn-validate float-end min-w-145" type="submit">
                            @if (!isSaving) {Sauvegarder}
                            @else {
                                <em class="fas fa-spinner fa-spin"></em>
                            }
                        </button>
                    </form>
                }
            </div>
        </div>
    </div>
}
