import {Component, OnInit} from '@angular/core';
import {
    Branch,
    HierarchicalLevel,
    MsDataService,
    MsServicesGiftService,
    MsUtilsS3Service,
    News,
    S3Resource,
    UserService
} from '@isifid/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {finalize, switchMap} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import {environment} from '../../../../../../environments/environment';


@Component({
    selector: 'app-news',
    templateUrl: './manage.component.html',
    styles: `
        .min-w-105 {min-width: 105px;}
        .min-w-145 {min-width: 145px;}
        `
})
export class NewsManageComponent implements OnInit {
    loading = true;
    createMode = true;
    hierarchicalLevels: Array<HierarchicalLevel> = [];
    branchesUnfiltered: Array<Branch> = [];
    branches: Array<Branch> = [];
    news = new News();
    newsForm: UntypedFormGroup;
    imageFile: File;
    imageUrl: string;
    isUploadingImage: boolean;
    isSaving: boolean;
    private formDataImage: FormData = new FormData();

    constructor(
        private readonly msServicesGiftService: MsServicesGiftService,
        private readonly userService: UserService,
        private readonly msUtilsS3Service: MsUtilsS3Service,
        private readonly msDataService: MsDataService,
        private readonly _snackBar: MatSnackBar,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly route: ActivatedRoute,
        private readonly router: Router
    ) {
    }

    ngOnInit() {
        this.getHierarchicalLevelsAndBranchesByClientId(this.userService.getUser().clientId);
    }

    save() {
        if (this.newsForm.invalid || this.isSaving) return;
        this.isSaving = true;

        const updatedNews = this.newsForm.getRawValue();
        delete updatedNews.permanentPublication;

        // Update values so backend doesn't break
        if (updatedNews.publicationAt === '0000-00-00T00:00:00') {
            updatedNews.publicationAt = '0000-01-01T00:00:00';
        } else if (updatedNews.publicationAt !== null) {
            updatedNews.publicationAt = this.convertDateTime(updatedNews.publicationAt);
        }

        if (updatedNews.unpublicationAt === '0000-00-00T00:00:00') {
            updatedNews.unpublicationAt = '0000-01-01T00:00:00';
        } else if (updatedNews.unpublicationAt !== null) {
            updatedNews.unpublicationAt = this.convertDateTime(updatedNews.unpublicationAt);
        }

        if (this.createMode) {
            this.msServicesGiftService.createNews(updatedNews)
                .pipe(finalize(() => this.isSaving = false))
                .subscribe({
                    next: () => this.throwSuccess(),
                    error: () => this.throwError('Erreur lors de la création de l\'actualité')
                });
        } else {
            this.msServicesGiftService.updateNews(this.news.id, updatedNews)
                .pipe(finalize(() => this.isSaving = false))
                .subscribe({
                    next: () => this.throwSuccess(),
                    error: () => this.throwError('Erreur lors de la mise à jour de l\'actualité')
                });
        }
    }

    copyImageUrlToClipboardCopied(): void {
        this._snackBar.open('Le chemin de l\'image a été copié.', 'OK');
    }

    changeImageFile(target: EventTarget): void {
        this.imageUrl = null;
        this.imageFile = (target as HTMLInputElement).files?.[0];
    }

    uploadImageForGiftNews(): void {
        this.isUploadingImage = true;
        this.fillFormDataImage();
        this.msUtilsS3Service.createCdnResource(this.formDataImage)
            .pipe(finalize(() => this.isUploadingImage = false))
            .subscribe({
                next: (s3Resource: S3Resource) => {
                    this._snackBar.open(
                        'Ensuite, l\'image a été téléchargée, copiez le chemin et injectez-le dans le champ de contenu.', 'OK'
                    );
                    this.imageUrl = `${environment.cdnUrl}/static/${s3Resource.uuid}.${s3Resource.extension}`;
                },
                error: () => this._snackBar.open('Le téléchargement de l\'image a échoué', 'X')
            });
    }

    private fillFormDataImage(): void {
        const prefixOfFileName = this.newsForm.get('clientId').value ? `gift/news/${this.newsForm.get('clientId').value}/` : 'gift/news/all/';
        this.formDataImage.set('file', this.imageFile, prefixOfFileName + this.imageFile.name);
    }

    private computeBranches(levelId: number) {
        if (isNaN(levelId)) return;
        this.branches = this.branchesUnfiltered.filter(t => t.levelId === levelId);
    }

    private throwError(message) {
        this._snackBar.open(message, 'X');
    }

    private throwSuccess() {
        this._snackBar.open('Mise à jour de la news effectuée', 'OK');
        this.router.navigateByUrl('/admin/news').then();
    }

    private initForm() {
        if (this.news.levelId) this.computeBranches(this.news.levelId);

        this.newsForm = this.formBuilder.group({
            title: [this.news.title, Validators.required],
            content: [this.news.content, Validators.required],
            clientId: this.userService.getUser().clientId,
            status: this.news.status,
            levelId: this.news.levelId,
            entityExternalId: [this.news.entityExternalId.map(number => number.toString())],
            publicationAt: {
                value: this.news.publicationAt || '0000-00-00T00:00:00',
                disabled: !this.news.publicationAt
            },
            unpublicationAt: {
                value: this.news.unpublicationAt || '0000-00-00T00:00:00',
                disabled: !this.news.unpublicationAt
            },
            permanentPublication: !this.news.publicationAt && !this.news.unpublicationAt
        });

        this.newsForm.controls.levelId.valueChanges.subscribe(levelId => {
            this.computeBranches(parseInt(levelId));

            if (this.branches.length === 0) {
                this.newsForm.controls.entityExternalId.setValue([]);
                this.newsForm.controls.entityExternalId.disable();
            } else {
                this.newsForm.controls.entityExternalId.setValue(this.branches.map(s => s.id));
                this.newsForm.controls.entityExternalId.enable();
            }
        });

        this.newsForm.controls.permanentPublication.valueChanges.subscribe(checked => {
            if (checked) {
                this.newsForm.patchValue({
                    publicationAt: '0000-00-00T00:00:00',
                    unpublicationAt: '0000-00-00T00:00:00'
                });
                this.newsForm.controls.publicationAt.disable();
                this.newsForm.controls.unpublicationAt.disable();
            } else {
                this.newsForm.patchValue({publicationAt: null, unpublicationAt: null});
                this.newsForm.controls.publicationAt.enable();
                this.newsForm.controls.unpublicationAt.enable();
            }
        });

        this.loading = false;
    }

    private getNews(): void {
        const id = Number(this.route.snapshot.paramMap.get('id'));
        if (id) this.createMode = false;

        if (!this.createMode) {
            this.msServicesGiftService.getNews(id).subscribe({
                next: (newsData) => {
                    this.news = {...this.news, ...newsData};
                    this.initForm();
                },
                error: () => this.throwError('Erreur lors de la récupération de la news')
            });
        } else this.initForm();
    }

    private getHierarchicalLevelsAndBranchesByClientId(clientId: number): void {
        this.branchesUnfiltered = [];
        this.hierarchicalLevels = [];
        this.msServicesGiftService.getHierarchicalLevelsByClientId(clientId)
            .pipe(
                switchMap(s => {
                    this.hierarchicalLevels = s.filter(t => t.position > 0 && t.role !== 'GIFT_HQ');
                    return this.msDataService.getBranchesByClientId(clientId);
                }),
                finalize(() => this.getNews())
            )
            .subscribe(s => {
                this.branchesUnfiltered = s.sort((a, b) => a.name.localeCompare(b.name));
            });
    }

    private convertDateTime(value: string): string {
        const date = new Date(value);
        date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
        return date as unknown as string;
    }
}
