import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {Branch, DateService, HierarchicalLevel} from '@isifid/core';

import {MatSnackBar} from '@angular/material/snack-bar';
import {MatSort} from '@angular/material/sort';

import {GiftService} from '../../../../shared/services/gift.service';
import {SponsorshipService} from '../../../../shared/services/sponsorship.service';
import {SponsorshipStat} from '../../models/stats.model';
import {StatsService} from '../../../../shared/services/stats.service';
import {ExcelService} from '@isifid/reward';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {GiftNetworkService} from '../../../../shared/services/gift-network.service';

@Component({
    selector: 'app-stats',
    templateUrl: './stats-sponsorship.component.html'
})
export class StatsSponsorshipComponent implements OnInit {
    colNames: Array<Array<string>>;
    columnsToDisplay: Array<Array<string>>;
    displayedColumns: Array<Array<string>>;

    dataSourceSponsor = new MatTableDataSource<any>();
    dataSourceSponsorRewarded = new MatTableDataSource<any>();
    dataSourceSponsored = new MatTableDataSource<any>();
    dataSourceInvites = new MatTableDataSource<any>();
    dataSourceGiftUsers = new MatTableDataSource<any>();
    dataSourceBranches = new MatTableDataSource<any>();
    loading = true;

    sponsors = [];
    sponsorsRewarded = [];
    sponsored = [];
    sponsorsFromGift = [];
    sponsorsFromOnline = [];
    sponsorsFromOthers = [];
    sponsoredFromGift = [];
    sponsoredFromOnline = [];
    sponsoredFromOthers = [];

    expectedSponsoredTypeMap: Map<string, number>;
    expectedSponsoredTypeNullValue = 'Non renseigné';

    giftUsers: Array<any> = [];
    giftUsersStats = [];
    giftUsersActive = 0;
    giftUsersEfficient = 0;

    branches: Array<Branch>;
    branchStats = [];
    branchesActive = 0;
    branchesEfficient = 0;

    invites = [];

    stats: SponsorshipStat;
    statsForm: FormGroup;

    private anonymizeConsumersData = false;
    private operation: any;
    private professionalEnabled = false;
    askForExpectedSponsoredType = false;

    @ViewChild('sponsorSort') sponsorSort: MatSort;
    @ViewChild('sponsorRewardedSort') sponsorRewardedSort: MatSort;
    @ViewChild('sponsoredSort') sponsoredSort: MatSort;
    @ViewChild('inviteSort') inviteSort: MatSort;
    @ViewChild('giftUserSort') giftUserSort: MatSort;
    @ViewChild('branchSort') branchSort: MatSort;
    @ViewChild('sponsorPaginator') sponsorPaginator: MatPaginator;
    @ViewChild('sponsorRewardedPaginator') sponsorRewardedPaginator: MatPaginator;
    @ViewChild('sponsoredPaginator') sponsoredPaginator: MatPaginator;
    @ViewChild('invitedPaginator') invitedPaginator: MatPaginator;
    @ViewChild('giftUserPaginator') giftUserPaginator: MatPaginator;
    @ViewChild('branchPaginator') branchPaginator: MatPaginator;

    constructor(
        readonly giftService: GiftService,
        private cdRef: ChangeDetectorRef,
        private readonly dateService: DateService,
        private readonly excelService: ExcelService,
        readonly giftNetworkService: GiftNetworkService,
        private readonly formBuilder: FormBuilder,
        public sponsorshipService: SponsorshipService,
        private readonly statsService: StatsService,
        private readonly _snackBar: MatSnackBar
    ) {
    }

    private static setSuccessRate(aoa: any[], nbSponsors: number, nbSponsored: number): void {
        aoa.push(['Nb total d\'inscriptions Parrains Potentiels', nbSponsors]);
        if (nbSponsored) {
            aoa.push(['Nb total d\'ouvertures de comptes filleuls', nbSponsored]);
            const successRate = nbSponsored * 100 / nbSponsors;
            if (isFinite(successRate)) {
                aoa.push(['Taux de transformation en % ', Math.round(successRate * 100) / 100]);
            } else {
                aoa.push(['Taux de transformation en %', 'Non disponible']);
            }
        }
    }

    ngOnInit(): void {
        this.initColumns();
        this.initOperation();
        this.resetForm();
        this.loading = false;
    }

    initColumns() {
        const customId = this.giftService.giftNetworkVariables.idName || 'ID';
        this.colNames = this.statsService.getStatsSponsorshipColNames(customId);
        this.displayedColumns = this.statsService.getStatsSponsorshipDisplayedColumns();
        this.columnsToDisplay = this.displayedColumns;
    }

    initOperation() {
        this.operation = this.sponsorshipService.operation;

        // If no active operation try to get disabled sponsorship operation
        if (!this.operation) this.operation = this.sponsorshipService.getSponsorshipOperationDisabled();

        if (!this.operation) this._snackBar.open('Aucune opération de parrainage', 'X');
        if (this.sponsorshipService.settings?.professionalEnabled) {
            this.professionalEnabled = true;
            this.colNames[1].push('Professionnel');
            this.colNames[2].push('Professionnel');
            this.colNames[3].push('Professionnel');
            this.displayedColumns[1].push('isPro');
            this.displayedColumns[2].push('isPro');
            this.displayedColumns[3].push('isPro');
            this.columnsToDisplay = this.displayedColumns;
        }

        this.askForExpectedSponsoredType = this.giftService.getConfigurationValue('askForExpectedSponsoredType');
        if (this.askForExpectedSponsoredType) {
            this.colNames[1].push('Type de parrainage attendu');
            this.colNames[2].push('Type de parrainage attendu');
            this.displayedColumns[1].push('expectedSponsoredType');
            this.displayedColumns[2].push('expectedSponsoredType');
            this.columnsToDisplay = this.displayedColumns;
        }
    }

    getStats(): void {
        if (this.statsForm.invalid) return;

        this.resetForm(false);
        this.loading = true;

        const filters = {
            start: this.dateService.computeDate(this.convertDateTime(this.statsForm.get('start').value), 'yyyy-MM-dd'),
            end: this.dateService.computeDate(this.convertDateTime(this.statsForm.get('end').value), 'yyyy-MM-dd', 1)
        };

        this.statsService.getSponsorshipStat(this.operation.id, [], filters).subscribe({
            next: (stats: SponsorshipStat) => {
                if (!stats) {
                    this.loading = false;
                    return;
                }

                this.stats = stats;
                this.sponsors = this.statsService.formatSponsorStats(this.stats.sponsorStats, this.sponsors, this.anonymizeConsumersData,
                    this.professionalEnabled);
                this.sponsorsRewarded = this.statsService.formatSponsorRewardedStats(this.stats.rewardedSponsorStats, this.sponsorsRewarded, this.anonymizeConsumersData,
                    this.professionalEnabled);
                this.sponsored = this.statsService.formatSponsoredStats(this.stats.sponsoredStats, this.sponsored, this.anonymizeConsumersData,
                    this.professionalEnabled);

                this.expectedSponsoredTypeMap = this.getExpectedSponsoredTypeMap();

                // Sort sponsors by their source
                this.sponsors.forEach(sponsor => {
                    if (sponsor.source === 'gift') this.sponsorsFromGift.push(sponsor);
                    else if (sponsor.source === 'online') this.sponsorsFromOnline.push(sponsor);
                    else this.sponsorsFromOthers.push(sponsor);
                });

                // Get the sponsored from gift by using sponsors from gift
                this.sponsorsFromGift.forEach(sponsor => {
                    const sponsorSponsored = this.sponsored.filter(sponsored => sponsored.sponsorCode === sponsor.sponsorCode);
                    this.sponsoredFromGift.push(...sponsorSponsored);
                });

                // Get the sponsored from direct by using sponsors from direct
                this.sponsorsFromOnline.forEach(sponsor => {
                    const sponsorSponsored = this.sponsored.filter(sponsored => sponsored.sponsorCode === sponsor.sponsorCode);
                    this.sponsoredFromOnline.push(...sponsorSponsored);
                });

                // Get the sponsored without source by using sponsors without source
                this.sponsorsFromOthers.forEach(sponsor => {
                    const sponsorSponsored = this.sponsored.filter(sponsored => sponsored.sponsorCode === sponsor.sponsorCode);
                    this.sponsoredFromOthers.push(...sponsorSponsored);
                })

                // Get invite (remove sponsor and sponsored from invites)
                this.statsService.getInvitesBySettingsId(this.sponsorshipService.settings.id, filters).subscribe({
                    next: (invites: Array<any>) => {
                        const filterInvite = invites.filter(invite => (invite.email || invite.mobile));
                        this.setInvites(filterInvite);
                    },
                    error: () => console.error('error while getting sponsorship invite'),
                    complete: () => {
                        this.dataSourceInvites = new MatTableDataSource<unknown>(this.invites);
                        this.dataSourceInvites.sort = this.inviteSort;
                        this.dataSourceInvites.paginator = this.invitedPaginator;
                    }
                });

                this.dataSourceSponsor = new MatTableDataSource<unknown>(this.sponsors);
                this.dataSourceSponsor.sort = this.sponsorSort;
                this.dataSourceSponsor.paginator = this.sponsorPaginator;
                this.dataSourceSponsorRewarded = new MatTableDataSource<unknown>(this.sponsorsRewarded);
                this.dataSourceSponsorRewarded.sort = this.sponsorRewardedSort;
                this.dataSourceSponsorRewarded.paginator = this.sponsorRewardedPaginator;
                this.dataSourceSponsored = new MatTableDataSource<unknown>(this.sponsored);
                this.dataSourceSponsored.sort = this.sponsoredSort;
                this.dataSourceSponsored.paginator = this.sponsoredPaginator;
                this.cdRef.detectChanges();
            }, error: () => {
                console.error('error while getting sponsorship stats');
                this._snackBar.open('Une erreur est survenue', 'X');
                this.loading = false;
            }, complete: () => {
                this.loadBranchAndGiftUserTab();
            }
        });
    }

    private setInvites(filterInvite) {
        filterInvite.forEach(invite => {
            if ((invite.email && !this.sponsored.find(sponsored => sponsored.email?.toLowerCase() === invite.email.toLowerCase())) ||
                (invite.mobile && !this.sponsored.find(sponsored => sponsored.mobile === invite.mobile))) {
                this.invites.push(invite);
            }
        });
    }

    loadBranchAndGiftUserTab() {
        this.branches = this.giftNetworkService.branches;
        this.giftUsers = this.giftNetworkService.giftUsers;

        this.branches.forEach(b => {
            const branchStat = {
                externalId: b.externalId,
                name: b.name,
                sponsorCount: 0,
                sponsoredCount: 0
            };

            const sponsorCount = this.sponsors
                .filter(t => t.branchCode === b.externalId).length;
            const sponsoredCount = this.sponsored
                .filter(t => t.branchCode === b.externalId).length;

            // Count active and efficient giftUsers
            if (sponsorCount) {
                branchStat.sponsorCount = sponsorCount;
                this.branchesActive++;
            }
            if (sponsoredCount) {
                branchStat.sponsoredCount = sponsoredCount;
                this.branchesEfficient++;
            }

            this.branchStats.push(branchStat);
        });

        this.giftUsers.forEach(giftUser => {
            const giftUserStat = {
                id: giftUser.id,
                firstName: giftUser.firstName,
                lastName: giftUser.lastName,
                email: giftUser.email,
                branchCode: giftUser.branchList?.length ? giftUser.branchList[0].toString().padStart(5, '0') : null,
                sponsorCount: 0,
                sponsoredCount: 0
            };

            if (giftUserStat.branchCode) {
                const sponsorCount = this.sponsors
                    .filter(sponsor => giftUserStat.email === sponsor.giftUserEmail).length;
                const sponsoredCount = this.sponsored
                    .filter(sponsored => giftUserStat.email === sponsored.giftUserEmail).length;

                // Count active and efficient giftUsers
                if (sponsorCount) {
                    giftUserStat.sponsorCount = sponsorCount;
                    this.giftUsersActive++;
                }
                if (sponsoredCount) {
                    giftUserStat.sponsoredCount = sponsoredCount;
                    this.giftUsersEfficient++;
                }
            } else return;

            this.giftUsersStats.push(giftUserStat);
        });

        this.dataSourceBranches = new MatTableDataSource<unknown>(this.branchStats);
        this.dataSourceBranches.sort = this.branchSort;
        this.dataSourceBranches.paginator = this.branchPaginator;
        this.dataSourceGiftUsers = new MatTableDataSource<unknown>(this.giftUsersStats);
        this.dataSourceGiftUsers.sort = this.giftUserSort;
        this.dataSourceGiftUsers.paginator = this.giftUserPaginator;
        this.cdRef.detectChanges();
        this.loading = false;
    }

    download() {
        if (!this.stats) return;

        const aoa: any[] = [[], [], [], [], [], [], []];
        // First tab
        this.setFirstTab(aoa[0]);

        const colNames = this.statsService.getStatsSponsorshipColNames(this.giftService.giftNetworkVariables.idName || 'ID', true);
        let levels = this.giftService.hierarchicalLevels;
        levels = levels.sort((a, b) => b.position - a.position);
        let nbLevels = 0;
        levels.forEach((level, i) => {
            // Add col for hierarchy (not for the first and last level)
            if (i > 0 && i < levels.length - 2) {
                colNames[1].splice(5, 0, level.entity + ' (nom)');
                colNames[1].splice(5, 0, level.entity + ' (code)');

                colNames[2].splice(5, 0, level.entity + ' (nom)');
                colNames[2].splice(5, 0, level.entity + ' (code)');

                colNames[3].splice(5, 0, level.entity + ' (nom)');
                colNames[3].splice(5, 0, level.entity + ' (code)');

                colNames[5].splice(5, 0, level.entity + ' (nom)');
                colNames[5].splice(5, 0, level.entity + ' (code)');

                colNames[6].splice(2, 0, level.entity + ' (nom)');
                colNames[6].splice(2, 0, level.entity + ' (code)');
                nbLevels += 1;
            }
        });

        // Generate aggregated giftUser stats tab
        this.setAggregatedGiftUserStatsTab(aoa[1], colNames, nbLevels);

        // Generate aggregated branches stats tab
        this.setAggregatedBranchStatsTab(aoa[2], colNames, nbLevels);

        // Check if we need to put back "pro" column
        if (this.professionalEnabled) {
            // Sponsor tab
            colNames[1].push('Professionnel');
            // Sponsor Rewarded tab
            colNames[2].push('Professionnel');
            // Sponsored tab
            colNames[3].push('Professionnel');
        }
        if (this.askForExpectedSponsoredType) {
            // Sponsor tab
            colNames[1].push('Type de parrainage attendu');
            // Sponsor Rewarded
            colNames[2].push('Type de parrainage attendu');
        }

        // Generate sponsor tab
        this.setSponsorTab(aoa[3], colNames, nbLevels);
        // Generate sponsor rewarded tab
        this.setSponsorRewardedTab(aoa[4], colNames, nbLevels);
        // Generate sponsored tab
        this.setSponsoredTab(aoa[5], colNames, nbLevels);

        // Generate invites tab
        if (this.invites) {
            aoa[6].push(this.colNames[4]);
            this.invites.forEach(invite => aoa[6].push([invite.firstName, invite.lastName, invite.email, invite.mobile,
                this.dateService.computeDate(new Date(invite.createdAt), 'yyyy-MM-dd')]));
        }


        const fileName = `statistiques-parrainage-
            ${this.dateService.computeDate(this.convertDateTime(this.statsForm.get('start').value), 'yyyy-MM-dd')}-
            ${this.dateService.computeDate(this.convertDateTime(this.statsForm.get('end').value), 'yyyy-MM-dd', 1)}`;
        this.excelService.exportAsExcelFileAoA(
            aoa, fileName, [
                'Récapitulatif',
                'Conseillers agrégés',
                'Agences agrégées',
                'Listing des parrains inscrits',
                'Listing des parrains récompensé',
                'Listing des filleuls',
                'Listing des invitations'
            ]
        );
    }

    resetForm(resetInputs: boolean = true): void {
        this.loading = true;

        this.dataSourceSponsor = new MatTableDataSource<any>();
        this.dataSourceSponsorRewarded = new MatTableDataSource<any>();
        this.dataSourceSponsored = new MatTableDataSource<any>();
        this.dataSourceBranches = new MatTableDataSource<any>();
        this.dataSourceInvites = new MatTableDataSource<any>();
        this.dataSourceGiftUsers = new MatTableDataSource<any>();

        this.sponsors = [];
        this.sponsorsRewarded = [];
        this.sponsored = [];
        this.sponsorsFromGift = [];
        this.sponsorsFromOnline = [];
        this.sponsorsFromOthers = [];
        this.sponsoredFromGift = [];
        this.sponsoredFromOnline = [];
        this.sponsoredFromOthers = [];

        this.expectedSponsoredTypeMap = null;

        this.giftUsersStats = [];
        this.giftUsersActive = 0;
        this.giftUsersEfficient = 0;

        this.branchStats = [];
        this.branchesActive = 0;
        this.branchesEfficient = 0;

        this.invites = [];

        this.stats = null;

        if (resetInputs) {
            this.statsForm = this.formBuilder.group({
                start: [this.dateService.computeDate(this.operation?.startDate || new Date(), 'yyyy-MM-dd'), [Validators.required, Validators.minLength(10),
                    Validators.maxLength(10)]],
                end: [this.dateService.computeDate(new Date(), 'yyyy-MM-dd'), Validators.required],
                branchCode: ''
            });
        }
        this.loading = false;
    }

    toggleAnonymizeConsumersData() {
        this.anonymizeConsumersData = !this.anonymizeConsumersData;
    }

    private setFirstTab(aoa: any[]) {
        aoa.push(['Récapitulatif']);
        aoa.push(['']);
        StatsSponsorshipComponent.setSuccessRate(aoa, this.sponsors.length, this.sponsored.length);
        aoa.push(['']);
        aoa.push(['dont Résultats de l\'action des conseillers : ']);
        StatsSponsorshipComponent.setSuccessRate(aoa, this.sponsorsFromGift.length, this.sponsored.length);
        aoa.push(['Nb de conseillers actifs *', this.giftUsersActive]);
        aoa.push(['Nb de conseillers efficaces **', this.giftUsersEfficient]);
        aoa.push(['Nb d\'agences actives *', this.branchesActive]);
        aoa.push(['Nb d\'agences efficaces **', this.branchesEfficient]);
        aoa.push(['']);
        aoa.push(['dont Résultats de l\'action online :', '']);
        StatsSponsorshipComponent.setSuccessRate(aoa, this.sponsorsFromOnline.length, this.sponsoredFromOnline.length);
        aoa.push(['']);
        aoa.push(['dont Résultats d\'une autre action :', '']);
        StatsSponsorshipComponent.setSuccessRate(aoa, this.sponsorsFromOthers.length, this.sponsoredFromOthers.length);
        if (this.askForExpectedSponsoredType) {
            aoa.push(['']);
            aoa.push(['Type de parrainages attendus :', '']);
            this.sponsorshipService.expectedSponsoredType.forEach(value => {
                aoa.push([value, this.expectedSponsoredTypeMap.get(value)]);
            });
            if (this.expectedSponsoredTypeMap.get(this.expectedSponsoredTypeNullValue)) {
                aoa.push([this.expectedSponsoredTypeNullValue, this.expectedSponsoredTypeMap.get(this.expectedSponsoredTypeNullValue)]);
            }
        }
        aoa.push(['']);
        aoa.push(['']);
        aoa.push(['Id operation parrain', this.stats.operationId]);
        aoa.push(['Id operation filleul', this.stats.operationId]);
        aoa.push(['Date debut stats', this.stats.startDate]);
        aoa.push(['Date fin', this.stats.endDate]);
        aoa.push(['']);
        aoa.push(['* Ayant enregistré un parrain potentiel']);
        aoa.push(['** Ayant obtenu l\'ouverture d\'au moins 1 compte']);
    }

    private setSponsorTab(aoa, colNames, nbLevels) {
        aoa.push(colNames[1]);

        if (!this.sponsors.length) aoa.push(['Aucun parrain ', this.sponsors.length]);

        let i = 1;
        this.sponsors.forEach(sponsorStats => {
            const hierarchyAoa = [];

            if (sponsorStats.branchCode === '-1') sponsorStats.branchCode = '';

            const branch = this.branches.find(b => b.externalId === sponsorStats.branchCode);
            const branchLevel = this.giftService.hierarchicalLevels.find(l => l.id === branch?.levelId);
            this.addHierarchyFromBranch(hierarchyAoa, nbLevels, branch, branchLevel);

            const data = [sponsorStats.giftUserFirstName, sponsorStats.giftUserLastName,
                sponsorStats.giftUserEmail, sponsorStats.branchCode,
                branch?.name || '', sponsorStats.firstName, sponsorStats.lastName,
                this.statsService.getExternalId(sponsorStats.externalId), sponsorStats.mobile,
                sponsorStats.email, this.dateService.computeDate(new Date(sponsorStats.sponsorCodeSentAt), 'yyyy-MM-dd'),
                sponsorStats.sponsorCode, sponsorStats.birthDate,
                sponsorStats.sponsoredCount
            ];

            if (this.professionalEnabled) data.push(sponsorStats.isPro);
            if (this.askForExpectedSponsoredType) data.push(sponsorStats.expectedSponsoredType);
            aoa.push(data);

            // Add hierarchy to aoa
            aoa[i].splice(5, 0, ...hierarchyAoa);
            i++;
        });
    }

    private setSponsorRewardedTab(aoa, colNames, nbLevels) {
        aoa.push(colNames[2]);

        if (!this.sponsorsRewarded.length) aoa.push(['Aucun parrain ', this.sponsorsRewarded.length]);

        let i = 1;
        this.sponsorsRewarded.forEach(sponsorStats => {
            const hierarchyAoa = [];

            if (sponsorStats.branchCode === '-1') sponsorStats.branchCode = '';

            const branch = this.branches.find(b => b.externalId === sponsorStats.branchCode);
            const branchLevel = this.giftService.hierarchicalLevels.find(l => l.id === branch?.levelId);
            this.addHierarchyFromBranch(hierarchyAoa, nbLevels, branch, branchLevel);

            const data = [sponsorStats.giftUserFirstName, sponsorStats.giftUserLastName,
                sponsorStats.giftUserEmail, sponsorStats.branchCode,
                branch?.name || '', sponsorStats.firstName, sponsorStats.lastName,
                this.statsService.getExternalId(sponsorStats.externalId), sponsorStats.mobile,
                sponsorStats.email, this.dateService.computeDate(new Date(sponsorStats.sponsorCodeSentAt), 'yyyy-MM-dd'),
                sponsorStats.sponsorCode, sponsorStats.birthDate,
                this.dateService.computeDate(new Date(sponsorStats.rewardCreatedAt), 'yyyy-MM-dd')
            ];

            if (this.professionalEnabled) data.push(sponsorStats.isPro);
            if (this.askForExpectedSponsoredType) data.push(sponsorStats.expectedSponsoredType);
            aoa.push(data);

            // Add hierarchy to aoa
            aoa[i].splice(5, 0, ...hierarchyAoa);
            i++;
        });
    }

    private setSponsoredTab(aoa, colNames, nbLevels) {
        aoa.push(colNames[3]);

        if (!this.sponsored.length) aoa.push(['Aucun filleul']);

        let i = 1;
        this.sponsored.forEach(sponsoredStats => {
            const hierarchyAoa = [];

            if (sponsoredStats.branchCode === '-1') sponsoredStats.branchCode = '';

            const branch = this.branches.find(b => b.externalId === sponsoredStats.branchCode);
            const branchLevel = this.giftService.hierarchicalLevels.find(l => l.id === branch?.levelId);
            this.addHierarchyFromBranch(hierarchyAoa, nbLevels, branch, branchLevel);

            aoa.push([sponsoredStats.giftUserFirstName, sponsoredStats.giftUserLastName,
                sponsoredStats.giftUserEmail, sponsoredStats.branchCode,
                branch?.name || '', sponsoredStats.firstName, sponsoredStats.lastName,
                this.statsService.getExternalId(sponsoredStats.externalId), sponsoredStats.mobile,
                sponsoredStats.email, sponsoredStats.rewardedAt, sponsoredStats.sponsorCode,
                this.statsService.getExternalId(sponsoredStats.sponsorExternalId), sponsoredStats.sponsorEmail,
                sponsoredStats.amountRewarded, sponsoredStats.birthDate, this.professionalEnabled ? sponsoredStats.isPro : null]);

            // Add hierarchy to aoa
            aoa[i].splice(5, 0, ...hierarchyAoa);
            i++;
        });
    }

    private addHierarchyFromBranch(hierarchyAoa: any[], nbLevels: number, branch: Branch, branchLevel: HierarchicalLevel) {
        const levelsToSkip = branchLevel?.position - 1;
        for (let i = 0; i < levelsToSkip; i++) {
            hierarchyAoa.push(branch.externalId);
            hierarchyAoa.push(branch.name);
        }

        while (branch?.parentBranchExternalId) {
            branch = this.branches.find(b => b.externalId === branch.parentBranchExternalId);

            if (branch) {
                hierarchyAoa.push(branch.externalId);
                hierarchyAoa.push(branch.name);
            } else break;
        }

        // Add empty cols for hierarchy if needed
        if (hierarchyAoa.length < (nbLevels * 2)) {
            while (hierarchyAoa.length < (nbLevels * 2)) hierarchyAoa.push('');
        }
    }

    private setAggregatedGiftUserStatsTab(aoa, colNames, nbLevels): void {
        aoa.push(colNames[5]);

        if (this.giftUsersStats.length === 0) {
            aoa.push(['Conseillers agrégés']);
        } else {
            let i = 1;
            for (const giftUser of this.giftUsersStats) {
                const hierarchyAoa = [];

                const branch = this.branches.find(b => b.externalId === giftUser.branchCode);
                const branchLevel = this.giftService.hierarchicalLevels.find(l => l.id === branch?.levelId);
                this.addHierarchyFromBranch(hierarchyAoa, nbLevels, branch, branchLevel);

                aoa.push([giftUser.firstName, giftUser.lastName, giftUser.email, giftUser.branchCode, branch?.name || '', giftUser.sponsorCount, giftUser.sponsoredCount]);

                // Add hierarchy to aoa
                aoa[i].splice(5, 0, ...hierarchyAoa);
                i++;
            }
        }
    }

    private setAggregatedBranchStatsTab(aoa, colNames, nbLevels): void {
        aoa.push(colNames[6]);

        if (this.branchStats.length === 0) {
            aoa.push(['Agences agrégées']);
        } else {
            let i = 1;
            for (const branchStat of this.branchStats) {
                const hierarchyAoa = [];

                const branch = this.branches.find(b => b.externalId === branchStat.externalId);
                const branchLevel = this.giftService.hierarchicalLevels.find(l => l.id === branch?.levelId);
                this.addHierarchyFromBranch(hierarchyAoa, nbLevels, branch, branchLevel);

                aoa.push([branchStat.externalId, branchStat.name, branchStat.sponsorCount, branchStat.sponsoredCount]);

                // Add hierarchy to aoa
                aoa[i].splice(2, 0, ...hierarchyAoa);
                i++;
            }
        }
    }

    private convertDateTime(value: string): string {
        const date = new Date(value);
        date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
        return date as unknown as string;
    }

    private getExpectedSponsoredTypeMap(): Map<string, number> {
        const expectedSponsoredType = Array.from(this.sponsorshipService.expectedSponsoredType);
        expectedSponsoredType.push(this.expectedSponsoredTypeNullValue);

        const expectedSponsoredTypeMap = new Map<string, number>();
        // fill the map with sponsors expected sponsored type and using possible values in the array expectedSponsoredType
        expectedSponsoredType.forEach((expectedSponsoredType) => expectedSponsoredTypeMap.set(expectedSponsoredType, 0));

        this.sponsors.forEach(sponsor => {
            if (sponsor.expectedSponsoredType && sponsor.expectedSponsoredType !== 'null') {
                expectedSponsoredTypeMap.set(sponsor.expectedSponsoredType,
                    expectedSponsoredTypeMap.get(sponsor.expectedSponsoredType) + 1);
            } else {
                expectedSponsoredTypeMap.set(this.expectedSponsoredTypeNullValue,
                    expectedSponsoredTypeMap.get(this.expectedSponsoredTypeNullValue) + 1);
            }
        });
        return expectedSponsoredTypeMap;
    }
}
