import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {Branch, HierarchicalLevel} from '@isifid/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {FormControl} from '@angular/forms';

interface DataSource {
    parentNotFound?: string;
    parentMissing?: string;
    childMissing?: string;
    filterValue?: string
}

@Component({
    selector: 'app-admin-network-control-branches',
    templateUrl: './branches.component.html'
})
export class NetworkControlBranchesComponent implements OnInit, AfterViewInit {
    filterControl = new FormControl('notRankedBranch');
    displayedColumns: string[] = ['externalId', 'name', 'parentBranchExternalId', 'levelId'];
    dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
    notRankedBranches: Array<Branch & DataSource> = [];
    isolatedBranches: Array<Branch & DataSource> = [];
    private filteredBranches: Array<Branch & DataSource> = [];
    @Input() private branches: Branch[];
    @Input() private levels: HierarchicalLevel[];
    @ViewChild(MatSort) private sort: MatSort;
    @ViewChild(MatPaginator) private paginator: MatPaginator;

    ngOnInit(): void {
        for (const s of this.branches) {
            // Not ranked branches
            if (!s.levelId || this.levels.every(t => t.id !== s.levelId)) {
                this.notRankedBranches.push({...s, filterValue: 'notRankedBranch'});
            }

            const parentNotFound = s.parentBranchExternalId
                && this.branches.every(t => t.externalId !== s.parentBranchExternalId);
            const parentMissing = !s.parentBranchExternalId;
            const childMissing = this.branches.every(t => t.parentBranchExternalId !== s.externalId);

            // The array Levels is sorted by position in desc order.
            const positionZeroOrFirst = this.levels.find(t => t.id === s.levelId)?.position <= 1;
            const positionTwoHighest = this.levels.findIndex(t => t.id === s.levelId) <= 1;

            this.isolatedBranches.push({
                ...s,
                // Branches with parent external id not found if the branch hierarchical level is not one of the two highest position
                parentNotFound: positionTwoHighest ? '-' : this.checkEmpty(parentNotFound),
                // Branches without parent external id if the branch hierarchical level is not at the second-highest position
                parentMissing: positionTwoHighest ? '-' : this.checkEmpty(parentMissing),
                // Branches with external id not found as parent if the branch hierarchical level is not at position 0
                childMissing: positionZeroOrFirst ? '-' : this.checkEmpty(childMissing),
                filterValue: 'isolatedBranch'
            });
        }

        // Remove branches without any problems
        this.isolatedBranches = this.isolatedBranches.filter(b =>
            (b.parentNotFound === 'O' || b.parentMissing === 'O' || b.childMissing === 'O') && this.notRankedBranches.every(t => t.id !== b.id)
        );

        // Get unique elements
        this.notRankedBranches = this.removeDuplicatedElement(this.notRankedBranches);
        this.isolatedBranches = this.removeDuplicatedElement(this.isolatedBranches);
        this.filteredBranches = this.removeDuplicatedElement([...this.notRankedBranches, ...this.isolatedBranches]);

        this.dataSource.data = this.filteredBranches;
        this.dataSource.filter = this.filterControl.value;
        this.filterControl.valueChanges.subscribe(s => {
            this.dataSource.filter = s;
            if (s === 'notRankedBranch') this.displayedColumns = ['externalId', 'name', 'parentBranchExternalId', 'levelId'];
            if (s === 'isolatedBranch') {
                this.displayedColumns = ['externalId', 'name', 'parentBranchExternalId', 'parentNotFound', 'parentMissing', 'childMissing'];
            }
        });
    }
    private checkEmpty(value): string {
        return value ? 'O' : 'N';
    }

    ngAfterViewInit(): void {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
    }

    getLevelEntity(levelId: number): string {
        return this.levels.find(s => s.id === levelId)?.entity;
    }

    private removeDuplicatedElement<T>(value: T): T {
        let index: number;
        return (value as any[]).reduce((acc, curr) => {
            index = acc.findIndex((s: any) => {
                return s.externalId === curr.externalId &&
                    s.name === curr.name &&
                    s.parentBranchExternalId === curr.parentBranchExternalId &&
                    s.levelId === curr.levelId;
            });
            if (index > -1) acc[index].filterValue += curr.filterValue;
            else acc.push(curr);
            return acc;
        }, []);
    }
}
