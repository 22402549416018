<div class="container-fluid m-0 px-5">

    <div class="row my-5">
        <h1>Gestion des utilisateurs</h1>
    </div>

    <div class="row my-5">
        <form (ngSubmit)="submit()" [formGroup]="manageForm" class="row">
            <div class="col-lg-6 mb-5">
                <mat-form-field appearance="fill">
                    <mat-label>Action</mat-label>
                    <mat-select data-cy="level" formControlName="action">
                        <mat-option [value]="'invite'">Inviter des utilisateurs</mat-option>
                        <mat-option [value]="'resendAccess'">Renvoyer l’accès</mat-option>
                        <mat-option [value]="'changeLevel'">Changer d'échelon</mat-option>
                        <mat-option [value]="'updateBranchCode'">Modifier le(s) code(s) agence(s)</mat-option>
                        <mat-option [value]="'disableUser'">Désactiver les utilisateurs</mat-option>
                    </mat-select>
                </mat-form-field>

                <div class="my-3">
                    @switch (action) {
                        @case ('invite') {
                            <p>
                                Vous permet d’inviter un ou plusieurs utilisateurs de la plateforme Gift directement à l’échelon concerné.<br>
                                Un email contenant le lien d’accès leur sera adressé si la case ci-dessous est cochée.
                            </p>
                        }
                        @case ('resendAccess') {
                            <p>
                                Vous pouvez renvoyer autant d’accès que vous le souhaitez, les utilisateurs recevront le lien par email.<br>
                                Si le compte de l’utilisateur n’existe pas, aucun email ne sera envoyé et cela sera signalé dans le tableau récapitulatif ci-dessous.
                            </p>
                        }
                        @case ('changeLevel') {
                            <p>
                                Vous pouvez mettre à jour le statut des utilisateurs suite aux promotions.
                            </p>
                        }
                        @case ('updateBranchCode') {
                            <p>
                                Vous pouvez ajouter un ou plusieurs code(s) agence(s) aux utilisateurs de votre choix.<br>
                                La case à cocher ci-dessus vous permet de supprimer les anciens codes agences de l’utilisateur, par exemple à la suite d’un changement de secteur.
                            </p>
                        }
                        @case ('disableUser') {
                            <p>
                                Vous pouvez désactiver les utilisateurs non présents dans le réseau (hors siège).<br>
                                Pour cela, merci d’envoyer la liste des utilisateurs de gift à jour et de sélectionner l'échelon.<br>
                                Les utilisateurs non présents dans cette liste seront désactivés.<br>
                                Un compte désactivé sera automatiquement réactivé lors de la prochaine connexion.
                            </p>
                        }
                    }
                </div>

                @if (action === 'invite' || action === 'changeLevel' || action === 'disableUser') {
                    <mat-form-field appearance="fill" data-cy="levelSelection">
                        <mat-label>Échelon</mat-label>
                        <mat-select data-cy="level" formControlName="levelId">
                            @for (level of giftService.hierarchicalLevels; track level.id) {
                                <mat-option [value]="level.id"
                                        data-cy="level">
                                    {{level.title}}
                                </mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                } @else if (action === 'updateBranchCode') {
                    <mat-checkbox [(ngModel)]="replaceBranchList"
                                  [ngModelOptions]="{standalone: true}">
                        Remplacer les codes agences existants
                    </mat-checkbox>
                }

                @if (action === 'invite') {
                    <mat-checkbox [(ngModel)]="sendInvite"
                                  [ngModelOptions]="{standalone: true}">
                        Envoyer une invitation par email
                    </mat-checkbox>
                }

                <div class="my-3 d-none d-lg-block">
                    <ng-container *ngTemplateOutlet="isSubmitButtonTemp" />
                </div>
            </div>

            <div class="col-lg-6">
                <mat-form-field appearance="fill">
                    <mat-label>Données requises</mat-label>
                    <textarea matInput class="w-100" data-cy="textarea" formControlName="data" rows="15"></textarea>
                </mat-form-field>

                <div class="my-3 d-lg-none">
                    <ng-container *ngTemplateOutlet="isSubmitButtonTemp" />
                </div>
            </div>
        </form>
    </div>

    <div class="row">
        <div class="col">
            <table [dataSource]="dataSource" [hidden]="!updatedUsers.length" aria-describedby="monitoring"
                   class="my-5 w-100"
                   mat-table matSort>
                <ng-container class="py-5" matColumnDef="email">
                    <th *matHeaderCellDef id="email" mat-header-cell>Email</th>
                    <td *matCellDef="let importedUser" class="text-grey" mat-cell>
                        {{importedUser.email}}
                    </td>
                </ng-container>

                <ng-container class="py-5" matColumnDef="message">
                    <th *matHeaderCellDef id="message" mat-header-cell>Message</th>
                    <td *matCellDef="let importedUser" class="text-grey" mat-cell>
                        {{importedUser.message}}
                    </td>
                </ng-container>

                <tr *matHeaderRowDef="['email', 'message']" mat-header-row></tr>
                <tr *matRowDef="let row; columns: ['email', 'message'];" mat-row></tr>
            </table>
        </div>
    </div>
</div>

<!--Templates-->
<ng-template #isLoadingTemp>
</ng-template>

<ng-template #isSubmitButtonTemp>
    <button [disabled]="loading" class="btn btn-validate mb-4 min-w-100" data-cy="submit" type="submit">
        @if (!loading) {Valider}
        @else {<em class="fas fa-spinner fa-spin"></em>}
    </button>

    <p>
        @switch (action) {
            @case ('invite') {
                Données requises : email code agence, une ligne par utilisateur, ex : raphaelle&#64;isifid.com 00350
            }
            @case ('resendAccess') {
                Données requises : email, une ligne par utilisateu
            }
            @case ('changeLevel') {
                Donnée requise :  email, une ligne par utilisateur
            }
            @case ('updateBranchCode') {
                Données requises: email code agence, une ligne par code agence, pour les utilisateurs ayant plusieurs codes agences, vous pouvez mettre plusieurs lignes contenant des codes agences différents, ils seront automatiquement ajoutés à cet utilisateur.
            }
            @case ('disableUser') {
                Données requises: email, une ligne par utilisateur
            }
        }
    </p>
</ng-template>
