import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService, UserService} from '@isifid/core';
import {GiftService} from '../../../../shared/services/gift.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DatePipe} from '@angular/common';
import {finalize} from 'rxjs';
import {environment} from '../../../../../environments/environment';

@Component({
    selector: 'app-request',
    templateUrl: './request.component.html',
    styles: `.min-w-105 {min-width: 105px}`
})
export class RequestComponent implements OnInit {
    loading: any;
    formGroup: FormGroup;
    sent = false;
    minDateForPublication: Date;

    ageOptions: {label: string, value: number}[] = [
        {label: 'Indifférent', value: 0},
        {label: 'Supérieur à ', value: 1},
        {label: 'Inférieur à ', value: 2}
    ];
    statuses: {label: string, value: number}[] = [
        {label: 'Indifférent', value: 0},
        {label: 'Client de l’établissement', value: 1}
    ];
    natureOptions: {label: string, value: number}[] = [
        {label: 'Cash', value: 0},
        {label: 'Bons d’achat Premium', value: 1},
        {label: 'Bons d’achat Platinium', value: 2}
    ];
    amountOptions: {label: string, value: number}[] = [
        {label: '50', value: 50},
        {label: '80', value: 80},
        {label: '100', value: 100},
        {label: 'Autre montant : ….', value: 0}
    ];
    maxReferralsOptions: {label: string, value: number}[] = [
        {label: '5', value: 5},
        {label: '10', value: 10},
        {label: 'Autre montant :', value: 0},
        {label: 'Aucun plafonnement', value: -1}
    ];
    registrationTypes: {label: string, value: number}[] = [
        {label: 'Inscription par le conseiller sur la plateforme Gift', value: 0},
        {label: 'Inscription par le client lui-même (suite message Banque ou flashage QR code Conseiller)', value: 1}
    ];
    disseminationTools: {label: string, value: number}[] = [
        {label: 'E-mailing', value: 0},
        {label: 'SMS', value: 1},
        {label: 'QR code (permet au filleul de prendre RV en flashant le QR Code du parrain)', value: 2},
        {label: 'Réseaux sociaux : Facebook/Instagram/Twitter', value: 3}
    ];
    appointmentReferralTypes: {label: string, value: number}[] = [
        {label: 'Module de prise de RV Isifid', value: 0},
        {label: 'Module de prise de RV de l’établissement bancaire', value: 1}
    ];
    networkAnimationTypes: {label: string, value: number}[] = [
        {label: 'Webinars Conseillers, animés par Isifid, avec la participation de la direction de l’établissement bancaire', value: 0},
        {label: 'Programme de récompense en bons d’achat des conseillers actifs', value: 1},
        {
            label: 'E-mailings personnalisés mensuels, destinés aux conseillers, portant sur les bonnes pratiques et les résultats par rapport au benchmark',
            value: 2
        }
    ];

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly apiService: ApiService,
        private readonly giftService: GiftService,
        private readonly userService: UserService,
        private readonly _snackBar: MatSnackBar,
        private readonly datePipe: DatePipe
    ) {

    }

    ngOnInit(): void {
        this.setMinDateForPublication();
        this.formGroup = this.formBuilder.group({
            // Sponsor
            sponsorAgeOption: ['', Validators.required],
            sponsorAge: [0, [Validators.min(0), Validators.required]],
            sponsorStatus: ['', Validators.required],
            sponsorNature: ['', Validators.required],
            sponsorAmountOption: ['', Validators.required],
            sponsorAmount: [0, [Validators.min(0), Validators.required]],
            sponsorMaxReferralsOption: ['', Validators.required],
            sponsorMaxReferrals: [0, [Validators.min(0), Validators.required]],
            sponsorRegistrationTypes: ['', Validators.required],
            sponsorDisseminationTools: ['', Validators.required],
            sponsorQuarterlyEmailing: false,
            // Sponsored
            sponsoredAgeOption: ['', Validators.required],
            sponsoredAge: [0, [Validators.min(0), Validators.required]],
            sponsoredNature: ['', Validators.required],
            sponsoredAmountOption: ['', Validators.required],
            sponsoredAmount: [0, [Validators.min(0), Validators.required]],
            sponsoredAppointmentReferralType: ['', Validators.required],
            // Additional Information
            networkAnimationTypes: ['', Validators.required],
            possibleSpecificities: ['', Validators.required],
            publication_at: ['', Validators.required]
        });

        this.formGroup.controls.sponsorAmountOption.valueChanges.subscribe(s => this.formGroup.controls.sponsorAmount.setValue(s));
        this.formGroup.controls.sponsoredAmountOption.valueChanges.subscribe(s => this.formGroup.controls.sponsoredAmount.setValue(s));

        this.formGroup.controls.sponsorAgeOption.valueChanges.subscribe(s => {
            if (s === 0) this.formGroup.controls.sponsorAge.setValue(s);
        });
        this.formGroup.controls.sponsoredAgeOption.valueChanges.subscribe(s => {
            if (s === 0) this.formGroup.controls.sponsoredAge.setValue(s);
        });
        this.formGroup.controls.sponsorMaxReferralsOption.valueChanges.subscribe(s => {
            if (s >= 0) this.formGroup.controls.sponsorMaxReferrals.setValue(s);
        });
    }

    submit(): void {
        if (this.formGroup.invalid || this.loading || this.sent) return;
        this.loading = true;
        const recipients = environment.emailRecipientsBusiness;
        const email = {
            sender: `Gift 🎁 ${this.giftService.client.name} <gift+operation@isifid.com>`,
            recipients: recipients,
            // Must be base64 encoded
            body: btoa(this.formatEmail().replace('=', '')),
            subject: `Demande opération parrainage : ${this.giftService.client.name} 📝`,
            attachments: null
        };
        this.apiService.post('/utils/email/send', email)
            .pipe(
                finalize(() => {
                    this.sent = true;
                    this.loading = false;
                })
            )
            .subscribe(
                {
                    next: () => this._snackBar.open('Demande de création d\'un nouveau parrainage envoyée !', 'Fermer', {duration: 2000}),
                    error: () => this._snackBar.open('Erreur lors de l\'envoi du mail !', 'Fermer', {duration: 2000})
                }
            );
    }

    private setMinDateForPublication(): void {
        const now = new Date();
        now.setMonth(now.getMonth() + 1);
        this.minDateForPublication = now;
    }

    private formatEmail(): string {
        let body = `<h1>Demande de parrainage :</h1><h2>Client : ${this.giftService.client.name} ,id: ${this.giftService.client.id}</h2>`;

        // Sponsor
        body += `Personne qui recommande (Parrain) <br>`;
        // Profile
        body += this.setBody();
        // Sponsored
        body += `<br>`;
        body += `Personne (Filleul) qui ouvre un compte suite à recommandation <br>`;
        // Profile
        if (this.formGroup.controls.sponsoredAgeOption.value >= 0) {
            body += `Âge : ${this.ageOptions[this.formGroup.controls.sponsoredAgeOption.value].label} ${this.formGroup.controls.sponsoredAge.value || ''} <br>`;
        }
        // Reward
        if (this.formGroup.controls.sponsoredNature.value >= 0) {
            body += `Nature : ${this.natureOptions[this.formGroup.controls.sponsoredNature.value].label} <br>`;
        }
        if (this.formGroup.controls.sponsoredAmount.value > 0) {
            body += `Montant : ${this.formGroup.controls.sponsoredAmount.value} € <br>`;
        }
        if (this.formGroup.controls.sponsoredAppointmentReferralType.value >= 0) {
            body += `Prise de rendez-vous Filleul : ${
                this.appointmentReferralTypes[this.formGroup.controls.sponsoredAppointmentReferralType.value].label
            } <br>`;
        }
        // Additional Information
        body += `<br>`;
        body += `Informations complémentaires <br>`;
        // Network Animation
        if (this.formGroup.controls.networkAnimationTypes.value?.length > 0) {
            body += `Animation Réseau : ${
                this.networkAnimationTypes.filter(s => this.formGroup.controls.networkAnimationTypes.value.includes(s.value)).map(s => s.label).toString()
            } <br>`;
        }
        // Possible specificities
        if (this.formGroup.controls.possibleSpecificities.value) {
            body += `Spécificités éventuelles : ${this.formGroup.controls.possibleSpecificities.value} <br>`;
        }
        // Planning
        if (this.formGroup.controls.publication_at.value) {
            body += `Planning : ${this.datePipe.transform(this.formGroup.controls.publication_at.value, 'dd/MM/yyyy')} <br>`;
        }

        body += `Demande effectuée par : ${this.userService.getUser().firstName || ''} ${this.userService.getUser().lastName || ''} 
        ${this.userService.getUser().email} ${this.userService.getUser().phone || ''}<br><br> Merci de ne pas répondre à cet email`;

        return body;
    }

    private setBody() {
        let body = '';

        if (this.formGroup.controls.sponsorAgeOption.value >= 0) {
            body += `Âge : ${this.ageOptions[this.formGroup.controls.sponsorAgeOption.value].label} ${this.formGroup.controls.sponsorAge.value || ''} <br>`;
        }
        if (this.formGroup.controls.sponsorStatus.value) {
            body += `Statut : ${this.statuses[this.formGroup.controls.sponsorStatus.value].label} <br>`;
        }
        // Reword
        if (this.formGroup.controls.sponsorNature.value >= 0) {
            body += `Nature : ${this.natureOptions[this.formGroup.controls.sponsorNature.value].label} <br>`;
        }
        if (this.formGroup.controls.sponsorAmount.value > 0) {
            body += `Montant : ${this.formGroup.controls.sponsorAmount.value} € <br>`;
        }
        if (this.formGroup.controls.sponsorMaxReferrals.value > 0) {
            body += `Plafonnement du nombre de filleuls : ${this.formGroup.controls.sponsorMaxReferrals.value} <br>`;
        } else if (this.formGroup.controls.sponsorMaxReferralsOption.value === -1) {
            body += `Plafonnement du nombre de filleuls : ${this.maxReferralsOptions[this.maxReferralsOptions.length - 1].label} <br>`;
        }
        // Registration Type
        if (this.formGroup.controls.sponsorRegistrationTypes.value.length > 0) {
            body += `Inscription sur la plateforme de recommandation : ${
                this.registrationTypes.filter(s => this.formGroup.controls.sponsorRegistrationTypes.value.includes(s.value)).map(s => s.label).toString()
            } <br>`;
        }
        // Dissemination Tools
        if (this.formGroup.controls.sponsorDisseminationTools.value.length > 0) {
            body += `Outils de diffusion de la recommandation : ${
                this.disseminationTools.filter(s => this.formGroup.controls.sponsorDisseminationTools.value.includes(s.value)).map(s => s.label).toString()
            } <br>`;
        }
        // Animation of people registered on the recommendation platform
        if (this.formGroup.controls.sponsorQuarterlyEmailing.value) {
            body += `Animation des personnes inscrites sur la plateforme de recommandation : 
                Emailing trimestriel faisant part de bonnes pratiques et/ou d’évolutions du programme (récompenses majorées, nouveaux cadeaux, 
                nouvelles approches de recommandation…) <br>`;
        }

        return body;
    }
}
