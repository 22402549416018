import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {GiftUser, MsCoreService, MsServicesGiftService} from '@isifid/core';
import {GiftService} from '../../../../../shared/services/gift.service';
import {catchError, concat, forkJoin, map, Observable, of, switchMap, tap, toArray} from 'rxjs';
import {MatTableDataSource} from '@angular/material/table';

@Component({
    selector: 'app-operations',
    templateUrl: './manage.component.html',
    styles: `.min-w-100 {min-width: 100px;}`
})
export class UsersManageComponent implements OnInit {
    // Action by default is 'invite' can be : resendAccess, changeLevel , updateBranchCode
    dataSource = new MatTableDataSource<any>([]);
    manageForm: FormGroup;
    updatedUsers: Array<any> = [];
    loading: boolean;
    replaceBranchList: boolean;
    sendInvite: boolean = true;
    private giftUsers: Array<any>;

    constructor(
        private readonly formBuilder: FormBuilder,
        public readonly giftService: GiftService,
        private readonly msCoreService: MsCoreService,
        private readonly msServicesGiftService: MsServicesGiftService
    ) {
    }

    ngOnInit(): void {
        this.loading = true;
        this.manageForm = this.formBuilder.group({
            data: ['', [Validators.required, Validators.minLength(1)]],
            levelId: this.giftService.hierarchicalLevels?.[0].id ?? '',
            forceSendAccess: false,
            action: 'invite'
        });
        this.manageForm.controls.action.valueChanges.subscribe(() => {
            this.manageForm.controls.levelId.setValue(this.giftService.hierarchicalLevels?.[0].id);
        });
        this.loading = false;
    }

    get action(): string {
        return this.manageForm.controls.action.value;
    }

    submit(): void {
        this.updatedUsers = [];
        this.dataSource = new MatTableDataSource<any>([]);
        this.parseData();
        if (!this.giftUsers.length) {
            this.manageForm.get('data').setErrors({invalid: true});
            return;
        }

        this.loading = true;
        switch (this.action) {
            case 'invite':
                this.inviteUsers().subscribe(() => this.loading = false);
                break;
            case 'resendAccess':
                this.resendAccessToUsers().subscribe(() => this.loading = false);
                break;
            case 'changeLevel':
                this.changeLevel().subscribe(() => this.loading = false);
                break;
            case 'updateBranchCode':
                this.updateBranchCode().subscribe(() => this.loading = false);
                break;
            case 'disableUser':
                this.disableUser().subscribe(() => {
                    this.loading = false;
                    this.dataSource.data = this.updatedUsers;
                });
                break;
            default:
                this.loading = false;
        }
    }

    parseData(): void {
        this.giftUsers = [];
        // Split by line in lower case
        const rawData = this.manageForm.get('data').value.toLowerCase().split(/\r?\n/);

        // Return if no data are provided
        if (rawData.length === 0) return;

        let isBranchRequired = false;
        if (this.action === 'invite') {
            const level = this.giftService.hierarchicalLevels.find((l) => +l.id === +this.manageForm.get('levelId').value)?.role;
            isBranchRequired = (level !== 'GIFT_HQ');
        }
        if (this.action === 'updateBranchCode') isBranchRequired = true;
        
        // Check if every email is valid
        this.setGiftUser(rawData, isBranchRequired);

        // Remove duplicates
        this.giftUsers = this.giftUsers.reduce((acc, curr, index, array) => {
            if (acc.every((t: any) => t.email !== curr.email)) {
                if (this.action === 'updateBranchCode') {
                    // Collect branchCode for the same email and add it as branchList
                    curr.branchList = array.filter(s => s.email === curr.email).map(s => s.branch);
                }
                acc.push(curr);
            }
            return acc;
        }, []);
    }

    private setGiftUser(rawData, isBranchRequired) {
        const regexEmail = new RegExp(/^[\w-\.+]+@([\w-]+\.)+[\w-]{2,4}$/);
        const regexBranch = new RegExp(/^\d{1,30}$/);
        const idLength = 5;
        for (const line of rawData) {
            // Remove double spaces & tabs then split by space
            const columns = line.replace(/\s+|\t/g, ' ').split(' ');
            const email = columns[0];
            // Skip if no email
            if (!email || (isBranchRequired && columns.length < 2)) continue;
            // Skip if invalid email
            if (!regexEmail.test(email)) continue;
            // Check the validity of branch format if present
            const branch = isBranchRequired ? columns[1] : null;
            if (branch && (branch.length > idLength || !regexBranch.test(branch))) continue;
            this.giftUsers.push({email, branch});
        }
    }

    getGiftUserByEmail(email, invite): Observable<any> {
        let user;
        // Get the user if it exists to obtain the UUID
        return this.msCoreService.getUsers(['uuid'], {email: email, clientId: this.giftService.settings.clientId})
            .pipe(
                switchMap(users => {
                    if (users.length) {
                        if (users.length > 1) {
                            console.error('Erreur : plusieurs utilisateurs avec le même email');
                            return of(null);
                        } else {
                            user = users[0];
                            // Check if a manager with this uuid exists
                            return this.msServicesGiftService.getGiftUserByUuid(user.uuid)
                                .pipe(
                                    catchError(() => {
                                        console.error('Erreur de récupération du manager');
                                        return of(null);
                                    })
                                );
                        }
                    } else if (invite) {
                        return this.invite(email).pipe(
                            switchMap(user => {
                                if (user) return this.msServicesGiftService.getGiftUserByUuid(user.uuid);
                                else {
                                    console.error('Erreur de récupération du manager');
                                    return of(null);
                                }
                            })
                        );
                    } else return of(null);
                }),
                switchMap(giftUser => {
                    // If a user exists but not the manager or if forceSendAccess
                    // Invite the manager
                    if (user && (!giftUser || this.manageForm.get('forceSendAccess').value === true && !invite)) {
                        return this.invite(email).pipe(
                            catchError(() => {
                                console.error(invite ? 'Erreur d\'invitation' : 'Erreur');
                                return of(null);
                            }),
                            switchMap(s => this.msServicesGiftService.getGiftUserByUuid(s.uuid).pipe(catchError(() => {
                                console.error('Erreur de récupération du manager');
                                return of(null);
                            })))
                        );
                    } else return of(giftUser);
                })
            );
    }

    invite(email): Observable<any> {
        const url = this.giftService.giftNetworkVariables.url || window.location.origin + '/auth';
        const emailSender = this.giftService.giftNetworkVariables.emailSender || null;

        const invitationData = {
            email: email,
            clientId: this.giftService.settings.clientId,
            emailSender,
            url,
            sendAccess: this.sendInvite,
            levelId: +this.manageForm.get('levelId').value
        };
        // LevelId is not needed for resendAccess, updateBranchCode or deleteUsers.
        if (this.action === 'updateBranchCode' || this.action === 'deleteUsers' || this.action === 'resendAccess') invitationData.levelId = null;

        // Init login for new member
        return this.msServicesGiftService.postInvitation(invitationData).pipe(catchError(() => of(null)));
    }

    replaceGiftUserBranch(email, branch, invite: boolean): Observable<string> {
        return (
            !invite ?
                this.msCoreService.getUsers(['uuid'], {email: email, clientId: this.giftService.settings.clientId}) :
                this.invite(email).pipe(map(s => [s]))
        ).pipe(
            switchMap(users => {
                if (!invite && !users?.[0]) return of('Problème de modification du PDV');
                else if (!users?.[0]) return of('Problème d\'invitation');

                // Get giftUser by uuid of user
                return this.msServicesGiftService.getGiftUserByUuid(users[0].uuid);
            }),
            switchMap(s => {
                return typeof s === 'string' ?
                    of(s) :
                    // Update the branchList of giftUser
                    this.msServicesGiftService.updateGiftUser({...s, branchList: [branch]});
            }),
            map(s => typeof s === 'string' ? s : 'Conseiller invité'),
            catchError(error => {
                console.error('Error in updateAdvisorBranch ', error);
                return of('Problème d\'invitation');
            })
        );
    }

    addGiftUserBranch(giftUser, branch): Observable<string> {
        // Finally update the branchList of the manager
        const branchList = giftUser.branchList ?? [];
        // No need to update the branch list when the branch is already in the list
        if (branchList.find(b => b === branch)) return of(null);

        branchList.push(branch);
        return this.msServicesGiftService.updateGiftUser({...giftUser, branchList: branchList})
            .pipe(
                map(() => 'OK'),
                catchError(() => of('Erreur de mise à jour du manager'))
            );
    }

    replaceGiftUserBranchList(giftUser, branchList): Observable<string> {
        if (!this.replaceBranchList) {
            branchList = [...giftUser.branchList, ...branchList].reduce((acc, curr) => {
                if (acc.every((s: number) => s !== parseInt(curr))) acc.push(curr);
                return acc;
            }, []);
        }

        return this.msServicesGiftService.updateGiftUser({...giftUser, branchList: branchList})
            .pipe(
                map(() => 'OK'),
                catchError(() => 'Erreur de mise à jour du manager')
            );
    }

    private inviteUsers(): Observable<string[]> {
        let observer: Observable<string>;
        const level = this.giftService.hierarchicalLevels.find((l) => l.id === +this.manageForm.get('levelId').value);
        const observers = this.giftUsers.map(el => {
            if (level.role === 'GIFT_ADVISOR') observer = this.replaceGiftUserBranch(el.email, el.branch, true);
            else if (level.role === 'GIFT_HQ') observer = this.invite(el.email).pipe(map(s => s === null ? 'Problème d\'invitation' : 'OK'));
            else {
                observer = this.getGiftUserByEmail(el.email, true).pipe(
                    catchError(() => of('Problème avec l\'échelon')),
                    switchMap(giftUser => {
                        if (!giftUser) return of('Problème d\'invitation');
                        else if (level.role === 'GIFT_MANAGER' || level.role === 'GIFT_DIRECTOR') {
                            return this.addGiftUserBranch(giftUser, Number(el.branch));
                        } else return of('Problème avec l\'échelon');
                    })
                );
            }

            return observer
                .pipe(
                    tap(message => this.updatedUsers.push({email: el.email, message}))
                );
        });

        return concat(...observers).pipe(
            toArray(),
            tap(() => this.dataSource.data = this.updatedUsers)
        );
    }

    private resendAccessToUsers(): Observable<void[]> {
        const url = this.giftService.giftNetworkVariables.url || window.location.origin + '/auth';
        const emailSender = this.giftService.giftNetworkVariables.emailSender || null;

        const observers = this.giftUsers.map(s => {
            const accessData = {
                email: s.email,
                clientId: this.giftService.settings.clientId,
                emailSender,
                url
            };
            return this.msServicesGiftService.getAccess(accessData).pipe(
                tap(() => this.updatedUsers.push({email: accessData.email, message: 'Invitation envoyée'})),
                catchError(() => {
                    this.updatedUsers.push({email: accessData.email, message: 'Problème d\'envoi d\'invitation'});
                    return of(null);
                })
            );
        });

        return concat(...observers).pipe(
            toArray(),
            tap(() => this.dataSource.data = this.updatedUsers)
        );
    }

    private updateBranchCode(): Observable<string[]> {
        const observers = this.giftUsers.map(s => {
            return this.getGiftUserByEmail(s.email, false)
                .pipe(
                    switchMap(t => !t ? of('Problème de modification du PDV') : this.replaceGiftUserBranchList(t, s.branchList)),
                    tap(message => this.updatedUsers.push({email: s.email, message}))
                );
        });

        return concat(...observers).pipe(
            toArray(),
            tap(() => this.dataSource.data = this.updatedUsers)
        );
    }

    private changeLevel(): Observable<null[]> {
        const level = this.giftService.hierarchicalLevels.find((l) => l.id === +this.manageForm.get('levelId').value);
        const observers = this.giftUsers.map(giftUser => {
            let url = this.giftService.giftNetworkVariables.url;
            if (!url || url === '') url = window.location.origin + '/auth';

            const invitationData = {
                clientId: this.giftService.settings.clientId,
                email: giftUser.email,
                branchCode: giftUser.branchCode,
                url,
                levelId: +level.id
            };
            if (!giftUser.branchCode) delete invitationData.branchCode;

            return this.msCoreService.getUsers(['uuid'], {email: giftUser.email, clientId: this.giftService.settings.clientId})
                .pipe(
                    catchError(() => {
                        this.updatedUsers.push({email: giftUser.email, message: 'Utilisateur non trouvé'});
                        return of(null);
                    }),
                    switchMap(users => {
                        if (!users?.length) {
                            this.updatedUsers.push({email: giftUser.email, message: 'Utilisateur non trouvé'});
                            return of(null);
                        } else {
                            // Get giftUser by uuid to get giftUser.id
                            return this.msServicesGiftService.getGiftUserByUuid(users[0].uuid)
                                .pipe(
                                    switchMap(s => this.msServicesGiftService.updateGiftUserRights(s.id, invitationData)),
                                    switchMap(() => {
                                        this.updatedUsers.push({email: invitationData.email, message: 'Niveau modifié'});
                                        return of(null);
                                    })
                                );
                        }
                    })
                );
        });

        return concat(...observers)
            .pipe(
                toArray(),
                tap(() => this.dataSource.data = this.updatedUsers)
            );
    }

    disableUser(): Observable<{email: string, message: string}[]> {
        return forkJoin([
                this.msServicesGiftService.getAllGiftUsers(['id', 'uuid'],{clientId: this.giftService.settings.clientId, levelId: this.manageForm.get('levelId').value}),
                this.msCoreService.getUsers(['id', 'uuid', 'email'],{clientId: this.giftService.settings.clientId})
            ])
            .pipe(
                catchError(() => {
                    return of(null);
                }),
                switchMap(([users, coreUsers]) => {
                    if (!users?.length) {
                        return of([]);
                    } else {
                        return forkJoin(
                            coreUsers.map((coreUser) => {
                                if (users.some(user => user.uuid === coreUser.uuid) && !this.giftUsers.some((s) => s.email === coreUser.email)) {
                                    return this.msServicesGiftService.getGiftUserByUuid(coreUser.uuid)
                                        .pipe(
                                            catchError(() => {
                                                return of(null);
                                            }),
                                            map(giftuser => giftuser ? {...giftuser, email: coreUser.email} : null)
                                        );
                                } else return of(null);
                            })
                        );
                    }
                }),
                switchMap((disabledUser: Array<GiftUser & {email:string}>) => {
                    const users = disabledUser.filter(s => !!s);
                    return forkJoin(
                        users.length
                            ? users.map(user => {
                                user.status = 'disabled';
                                return this.msServicesGiftService.updateGiftUser(user)
                                    .pipe(
                                        catchError(() => of(null)),
                                        map(() => {
                                            return ({email: user.email, message: 'désactivé'});
                                        })
                                    );
                                })
                            : [of({email: "", message: "Aucun utilisateur n'a été désactivé."})]
                    );
                }),
                tap((data: {email: string, message: string}[]) => {
                    this.updatedUsers = data.filter(s => !!s);
                })
            );
    }
}
